      <template>
  <div>
    <div class="bgc" style="position: relative">
      <el-table
        ref="table"
        id="tableID"
        :data="tableData"
        style="width: 100%"
        class=""
      >
        <el-table-column align="center" prop="date" label="志愿" width="80">
        </el-table-column>
        <el-table-column align="center" prop="date1" label="院校名称">
        </el-table-column>
        <el-table-column align="center" prop="date2" label="院校代码">
        </el-table-column>
        <el-table-column align="center" prop="date3" label="专业名称">
        </el-table-column>
        <el-table-column align="center" prop="date4" label="专业代码">
        </el-table-column>
      </el-table>
      <div class="tablebg"></div>
    </div>
    <div>
      <el-button style="margin-top: 30px" type="primary" @click="viewResult">
        查看结果
      </el-button>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div class="f-row f-se">
        <el-input style="width: 40%" placeholder="请输入激活码"></el-input>
        <el-button>激活</el-button>
      </div>
      <div style="margin: 20px auto auto auto; text-align: center">
        <el-button type="primary">打开小程序购买</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "autoresult",
  data() {
    return {
      dialogVisible: false,
      tableData: [{ date: 1 }, { data: 1 }, { data: 1 }, { data: 1 }],
    };
  },

  mounted() {
    let height = this.$refs.table.$el.offsetHeight;
    console.log(height);
  },
  methods: {
    viewResult() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.bgc {
  background-color: red !important;
}
</style>
      